// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../styles/assets/logo.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".logo {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: center center;\n  background-repeat: no-repeat;\n}\n\n.thumbnail .logo {\n  background-size: contain;\n}\n\n.presentation-wrapper {\n  align-items: center;\n  bottom: 0;\n  display: flex;\n  justify-content: center;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  width: 100%;\n  z-index: 0;\n}\n\n.presentation {\n  display: flex;\n  height: 100%;\n  width: 100%;\n}\n\n#TD-ContextMenu {\n  display: none;\n}", "",{"version":3,"sources":["webpack://./src/styles/images.scss","webpack://./src/components/tldraw/index.scss"],"names":[],"mappings":"AAAA;EACE,yDAAA;EACA,kCAAA;EACA,4BAAA;ACCF;;ADGE;EACE,wBAAA;ACAJ;;AANA;EACE,mBAAA;EACA,SAAA;EACA,aAAA;EACA,uBAAA;EACA,YAAA;EACA,kBAAA;EACA,MAAA;EACA,WAAA;EACA,UAAA;AASF;;AANA;EACE,aAAA;EACA,YAAA;EACA,WAAA;AASF;;AANA;EACE,aAAA;AASF","sourcesContent":[".logo {\r\n  background-image: url(./assets/logo.png);\r\n  background-position: center center;\r\n  background-repeat: no-repeat;\r\n}\r\n\r\n.thumbnail {\r\n  .logo {\r\n    background-size: contain;\r\n  }\r\n}\r\n","@import 'src/styles/images';\r\n\r\n.presentation-wrapper {\r\n  align-items: center;\r\n  bottom: 0;\r\n  display: flex;\r\n  justify-content: center;\r\n  height: 100%;\r\n  position: absolute;\r\n  top: 0;\r\n  width: 100%;\r\n  z-index: 0;\r\n}\r\n\r\n.presentation {\r\n  display: flex;\r\n  height: 100%;\r\n  width: 100%;\r\n}\r\n\r\n#TD-ContextMenu {\r\n  display: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
